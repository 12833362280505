.home-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    max-height: 90%;
  }

  h1 {
    color: #fff;
    font-size: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;

    &::before {
      content: '<h1>';
      font-family: 'La Belle Aurore';
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -40px;
      left: 15px;
      opacity: 0.6px;
    }

    &::after {
      content: '<h1/>';
      font-family: 'La Belle Aurore';
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: 18px;
      margin-left: 20px;
      animation: fadeIn 1s 1.7s backwards;
      opacity: 0.6;
    }

    img {
      width: 32px;
      margin-left: 20px;
      opacity: 0;
      height: auto;
      animation: rotateIn 1s linear both;
      animation-delay: 1.4s;
    }
  }

  h2 {
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 400;
    font-size: 11px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }

  .flat-button {
    color: #ffd700;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #ffd700;
    margin-top: 25px;
    float: left;
    animation: fadeIn 1st 1.8s backwards;

    &:hover {
      background: #ffd700;
      color: #333;
    }
  }

  .stage-cube-cont {
    width: 50%;
    height: 100%;
    top: 0;
    padding-top: 18%;
    margin-left: 0;
    position: absolute;
    right: 0;
    overflow: hidden;
  }

  .cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);

    div {
      position: absolute;
      width: 200px;
      height: 200px;
      border: 1px solid #ccc;
      background: rgba(255, 255, 255, 0.4);
      font-size: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 20px 0 lightyellow;
    }

    .face1 {
      transform: translateZ(100px);
    }

    .face2 {
      transform: rotateY(90deg) translateZ(100px);
    }

    .face3 {
      transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
    }

    .face4 {
      transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
    }

    .face5 {
      transform: rotateY(-90deg) translateZ(100px);
    }

    .face6 {
      transform: rotateX(-90deg) rotateZ(-90deg) translateZ(100px);
    }
  }

  @keyframes spincube {
    from,
    to {
      transform: rotateX(0) rotateY(0) rotateZ(0);
    }

    16% {
      transform: rotateY(-90deg);
    }

    33% {
      transform: rotateY(-90deg) rotateZ(90deg);
    }

    50% {
      transform: rotateY(-180deg) rotateZ(90deg);
    }

    66% {
      transform: rotateY(-270deg) rotateZ(90deg);
    }

    83% {
      transform: rotateY(90deg);
    }
  }

  /* On screens that are 992px or less, tablets */
  @media screen and (max-width: 992px) {
    .stage-cube-cont {
      width: 50%;
      padding-top: 30%;
    }
  }

  /* On screens that are 600px or less, mobile */
  @media screen and (max-width: 600px) {
    .stage-cube-cont {
      width: 50%;
      padding-top: 50%;
    }

    .cubespinner {
      transform-origin: 50px 50px 0;
      margin-left: calc(50% - 50px);

      div {
        font-size: 75px;
        width: 150px;
        height: 150px;
      }

      .face1 {
        transform: translateZ(75px);
      }

      .face2 {
        transform: rotateY(90deg) translateZ(75px);
      }

      .face3 {
        transform: rotateY(90deg) rotateX(90deg) translateZ(75px);
      }

      .face4 {
        transform: rotateY(180deg) rotateZ(90deg) translateZ(75px);
      }

      .face5 {
        transform: rotateY(-90deg) translateZ(75px);
      }

      .face6 {
        transform: rotateX(-90deg) rotateZ(-90deg) translateZ(75px);
      }
    }
  }
}
